import React from "react";

export default ({ color }) => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.16668 11.0007H34.8333C35.8417 11.0007 36.6667 10.1757 36.6667 9.16732C36.6667 8.15898 35.8417 7.33398 34.8333 7.33398H9.16668C8.15835 7.33398 7.33335 8.15898 7.33335 9.16732C7.33335 10.1757 8.15835 11.0007 9.16668 11.0007ZM36.96 14.3007C36.795 13.4573 36.0433 12.834 35.1633 12.834H8.83668C7.95668 12.834 7.20501 13.4573 7.04001 14.3007L5.20668 23.4673C4.98668 24.604 5.84835 25.6673 7.00335 25.6673H7.33335V34.834C7.33335 35.8423 8.15835 36.6673 9.16668 36.6673H23.8333C24.8417 36.6673 25.6667 35.8423 25.6667 34.834V25.6673H33V34.834C33 35.8423 33.825 36.6673 34.8333 36.6673C35.8417 36.6673 36.6667 35.8423 36.6667 34.834V25.6673H36.9967C38.1517 25.6673 39.0133 24.604 38.7933 23.4673L36.96 14.3007V14.3007ZM22 33.0006H11V25.6673H22V33.0006Z" fill="#333333" />
    <path d="M16.6981 31.429C17.6704 30.9899 18.1026 29.8457 17.6635 28.8734C17.2244 27.9011 16.0802 27.4689 15.1079 27.908C14.1356 28.3471 13.7034 29.4913 14.1425 30.4636C14.5816 31.4359 15.7258 31.8681 16.6981 31.429Z" fill="#333333" />
    <path d="M17.7091 30.0193C18.5046 29.6601 18.8583 28.7239 18.499 27.9284C18.1397 27.1329 17.2036 26.7792 16.4081 27.1385C15.6125 27.4978 15.2589 28.434 15.6182 29.2295C15.9775 30.025 16.9136 30.3786 17.7091 30.0193Z" fill="#73A77B" />
    <path d="M31.5138 27.554C31.122 26.5487 29.9895 26.0514 28.9842 26.4432C27.9789 26.8349 27.4816 27.9675 27.8734 28.9728C28.2652 29.978 29.3977 30.4754 30.403 30.0836C31.4083 29.6918 31.9056 28.5593 31.5138 27.554Z" fill="#333333" />
    <path d="M30.1441 26.4573C29.8235 25.6348 28.8969 25.2279 28.0744 25.5485C27.2519 25.869 26.845 26.7957 27.1656 27.6182C27.4861 28.4407 28.4127 28.8476 29.2352 28.527C30.0577 28.2065 30.4646 27.2798 30.1441 26.4573Z" fill="#FFB000" />
    <path d="M30.8207 2.15062C29.7873 2.2392 29.0214 3.14872 29.11 4.1821C29.1986 5.21547 30.1081 5.98138 31.1415 5.89281C32.1749 5.80423 32.9408 4.89471 32.8522 3.86133C32.7636 2.82796 31.8541 2.06205 30.8207 2.15062Z" fill="#333333" />
    <path d="M29.4384 3.11631C28.5929 3.18879 27.9662 3.93294 28.0387 4.77843C28.1112 5.62392 28.8553 6.25057 29.7008 6.1781C30.5463 6.10563 31.1729 5.36147 31.1005 4.51598C31.028 3.67049 30.2838 3.04384 29.4384 3.11631Z" fill="#F76E9C" />
  </svg>
);
